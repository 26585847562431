export const initialState: CampaignState = {
  campaignList: [],
  campaignListHidden: [],
  campaignFeaturedList: [],
  campaignCategories: [],
  campaignStatuses: [],
  campaignPromotion: {
    WelcomeTitle: null,
    welcomeIcon: null,
    WelcomeDescription: null,
    WelcomeButtonLabel: null,
    CampaignType: null
  },
  campaignReferralInfo: {
    ReferralConfiguration: {
      ReferralCampaignsConfigurationID: null,
      CampaignID: null,
      ReferralTitle: null,
      ReferralDescription: null,
      ReferralImageID: null,
      RewardsForReferrer: null,
      RewardsForReferee: null,
      CopyLinkText: null,
      CopyLinkUrl: null,
      ReferrerTargetGroupID: null,
      RefereeTargetGroupID: null,
      IsActionRequire: false,
      ReferralImageURL: null,
      HowItWork: null
    },
    ReferrerRecord: {
      UserID: null,
      CampaignID: null,
      ReferralCouponCode: null
    },
    ReferralInstructions: [],
    ReferralStatistics: {
      codeEntered: 0,
      completedAction: 0
    }
  }
}

export const defaultAction: CampaignListAction = {
  type: '',
  payload: []
}

export const defaultCampaign: Campaign = {
  CampaignCategoryID: 0,
  CampaignID: 0,
  SubHeader: '',
  headerImageUrl: '',
  Category: '',
  Title: '',
  SubTitle: '',
  RewardPerUser: 0,
  status: '',
  Description: '',
  earningsAllowedInTimeFrame: 0,
  timeFrameLengthInSeconds: 0,
  providers: [],
  target: 0,
  targetUnits: '',
  ActionButtonUrl: '',
  ActionButtonLabel: '',
  CampaignType: null,
  badges: []
}
