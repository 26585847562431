import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import { CampaignStatusList } from '../campaignStatus'
import { selectCampaignStatus } from '../selectors'
import CampaignIco from '../CampaignIco/CampaignIco'
import DynamicIcon from '../../ui/customIcons/DynamicIcon/DynamicIcon'
import { selectConfig } from '../../config/selectors'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router'
import './CampaignDrawer.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import placeholderCampaign from '../../../assets/images/campaign/placeholder-campaign.png'
import './CampaignDrawerUpdated.scss'
import _ from 'lodash'
import { CampaignTypes, MAX_BADGE_LIMIT } from '../CampaignConstants'
import StreakProgressUpdated from '../../ui/Streak/StreakProgressUpdated'
import StreakProgressWithTimeDistinct from '../../ui/Streak/StreakProgressWithTimeDistinct'
import MDEditor from '@uiw/react-md-editor'
import { isValidUrl, isExternal } from '../../utils'
import TierProgress from '../../ui/Tier/TierProgress'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'

interface CampaignDrawerUpdatedProps {
  campaignItem: Campaign
  isCampaignDetailsOpen: boolean
  setIsCampaignDetailsOpen: (isCampaignDetailsOpen: boolean) => void
  handleOpenUnavailableMessage: () => void
}

const CampaignDrawerUpdated: FC<CampaignDrawerUpdatedProps> = ({
  campaignItem,
  isCampaignDetailsOpen,
  setIsCampaignDetailsOpen,
  handleOpenUnavailableMessage
}) => {
  const { trackEvent } = useTracking()
  const { t } = useTranslation()
  const campaignStatus: CampaignStatus | undefined = useSelector(
    selectCampaignStatus(campaignItem.CampaignID)
  )
  const config: ConfigState = useSelector(selectConfig)
  const history = useHistory()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const showsPts =
    campaignItem.CampaignType === CampaignTypes.STREAK
      ? campaignStatus?.streakLevelRewards > 0
      : campaignItem.CampaignType === CampaignTypes.TIER
        ? campaignStatus?.currentTierPoints > 0
        : campaignItem.RewardPerUser > 0

  useEffect(() => {
    if (campaignStatus !== undefined && isCampaignDetailsOpen) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Detail View',
        payload: {
          status: campaignStatus.status,
          campaignID: campaignItem.CampaignID
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignStatus, isCampaignDetailsOpen])

  const handleIconClick = (): void => {
    if (campaignStatus?.status === CampaignStatusList.TimeLimited) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Timer Pressed',
        payload: {
          origin: 'campaign detail'
        }
      })

      handleOpenUnavailableMessage()
    } else if (campaignStatus?.status === CampaignStatusList.Locked) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Lock Pressed',
        payload: {
          origin: 'campaign detail'
        }
      })
    }
  }

  const isActionBtn = (): boolean =>
    campaignStatus?.status === CampaignStatusList.Available &&
    Boolean(campaignItem.ActionButtonUrl)

  const getPointsField = (): string => {
    if (config.WordsForPoints.short != null) {
      return t([config.WordsForPoints.short])
    }
    return t`pts`
  }

  const getPoints = (): string | undefined => {
    if (showsPts) {
      return `${
        campaignItem.CampaignType === CampaignTypes.STREAK
          ? +campaignStatus?.streakLevelRewards
          : campaignItem?.CampaignType === CampaignTypes.TIER
            ? +campaignStatus?.currentTierPoints
            : campaignItem.RewardPerUser
      } ${getPointsField()}`
    }
  }
  const trackCTAClick = (campaignItem: Campaign): void => {
    trackEvent({
      action: 'Campaign Detail CTA',
      category: AnalyticsCategory.Campaigns,
      payload: {
        title: campaignItem.Title,
        description: campaignItem.Description,
        campaignID: campaignItem.CampaignID
      }
    })
  }

  const campaignButtonClick = (event: any = null): void => {
    setIsCampaignDetailsOpen(false)
    if (event != null) {
      event.preventDefault()
    }

    if (isValidUrl(campaignItem.ActionButtonUrl)) {
      const external = isExternal(campaignItem.ActionButtonUrl)
      if (external) {
        window.open(campaignItem.ActionButtonUrl)
      } else {
        history.push(campaignItem.ActionButtonUrl)
      }
    }

    trackCTAClick(campaignItem)
  }

  const getImg = (): string => {
    if (campaignItem?.headerImageUrl != null) {
      return campaignItem.headerImageUrl
    }
    return placeholderCampaign
  }
  const isUnavailable =
    campaignStatus?.status === CampaignStatusList.TimeLimited ||
    campaignStatus?.status === CampaignStatusList.Locked

  return (
    <BaseDrawer
      isOpen={isCampaignDetailsOpen}
      onClose={() => setIsCampaignDetailsOpen(false)}
      customMargin={matchesMd ? '20px' : ''}
      isFromUpdatedUI={!matchesMd}
      isFromUpdatedDesktopUI={matchesMd}
    >
      <div className={`campaign-drawer-updated ${matchesMd ? 'maxh-75' : ''}`}>
        <div
          className={`campaign-drawer-img ${isUnavailable ? 'locked' : ''} ${
            matchesMd ? 'updated-img' : ''
          }`}
          style={{ backgroundImage: `url(${getImg()})` }}
        />
        <div
          className={`campaign-drawer__status-ico ${
            matchesMd ? 'icon-updated' : ''
          }`}
        >
          {campaignStatus !== undefined && (
            <CampaignIco
              campaignStatus={campaignStatus}
              handleBadge={handleIconClick}
              campaignLocked={
                campaignStatus?.status === CampaignStatusList.Locked
              }
            />
          )}
        </div>

        <div className={`campaign-drawer-details ${matchesMd ? 'p-2' : ''}`}>
          {!_.isEmpty(campaignItem.SubHeader) && (
            <div className='subheader-1'>{campaignItem.SubHeader}</div>
          )}

          <div>
            <span className='title'>{campaignItem.Title}</span>
          </div>

          {campaignItem.badges.length > 0 && (
            <div className='badges-container'>
              {campaignItem?.badges.map(
                (badge, i) =>
                  i < MAX_BADGE_LIMIT && (
                    <span className='campaign-drawer__timer' key={`badge-${i}`}>
                      <DynamicIcon
                        iconName={badge.BadgeIcon}
                        className='icon__ico m-right-3'
                      />
                      <span>{badge.BadgeLabel}</span>
                    </span>
                  )
              )}
            </div>
          )}

          {showsPts && (
            <div
              className={`points ${isUnavailable ? 'locked-background' : ''}`}
            >
              {getPoints()}
            </div>
          )}

          <div
            className={`description ${
              matchesMd &&
              !isUnavailable &&
              campaignItem?.CampaignType !== CampaignTypes.TIER
                ? 'description-updated'
                : ''
            }`}
          >
            <MDEditor.Markdown
              source={campaignItem.Description}
              style={{ whiteSpace: 'pre-wrap' }}
            />
          </div>
          {campaignItem?.CampaignType === CampaignTypes.STREAK &&
            campaignStatus != null &&
            (
              <>
                {campaignStatus?.hasTimeDistinct ? (
                  <StreakProgressWithTimeDistinct
                    currentLevel={campaignStatus?.streakCurrentLevel}
                    totalLevel={campaignStatus?.streakTotalLevels}
                    daysLeft={campaignStatus?.streakDaysLeft}
                    completed={campaignStatus?.streakCompleted}
                    streakTarget={campaignStatus?.streakTarget}
                    behavioursCount={
                      campaignStatus?.behavioursCountInTimeDistinct
                    }
                    timeDistinctCount={campaignStatus?.timeDistinctCount}
                    timeDistinctUnit={campaignStatus?.timeDistinctUnit}
                  />
                ) : (
                  <StreakProgressUpdated
                    trips={campaignStatus?.progress}
                    completion={campaignStatus?.streakCurrentLevelCompletion}
                    currentLevel={campaignStatus?.streakCurrentLevel}
                    totalLevel={campaignStatus?.streakTotalLevels}
                    daysLeft={campaignStatus?.streakDaysLeft}
                    completed={campaignStatus?.streakCompleted}
                    streakLevelTarget={campaignStatus?.streakTarget}
                    isFromDetailView
                  />
                )}
              </>
            )}
          {campaignItem?.CampaignType === CampaignTypes.TIER &&
            campaignStatus != null &&
            (
              <div
                className={`${
                  !_.isEmpty(campaignItem.SubTitle)
                    ? matchesMd
                      ? 'mt-1'
                      : 'mt-05'
                    : ''
                } ${isUnavailable ? 'mb-1' : ''}`}
              >
                <TierProgress
                  tierProperties={campaignStatus?.tierPropertiesForCampaign}
                  tierProgress={campaignStatus?.tierProgress}
                  tierType={campaignStatus?.tierType}
                  isDetailView
                  isLastTierCompleted={campaignStatus?.isLastTierCompleted}
                />
              </div>
            )}
          {isUnavailable && (
            <div className='unavailable-campaign-text'>
              {campaignStatus?.status === CampaignStatusList.Locked ? (
                t`RequiresAppConnect`
              ) : campaignStatus?.status === CampaignStatusList.TimeLimited ? (
                <div>
                  {t`CampaignUnavailable`}
                  <p className='info'>{t`ReachedEarningLimit`}</p>
                </div>
              ) : null}
            </div>
          )}
        </div>

        {isActionBtn() && (
          <div className={`cta ${matchesMd ? 'cta-updated' : ''}`}>
            <Button
              onClick={campaignButtonClick}
              onTouchEnd={campaignButtonClick}
              className='btn'
            >
              {campaignItem.ActionButtonLabel}
            </Button>
          </div>
        )}
      </div>
    </BaseDrawer>
  )
}

export default CampaignDrawerUpdated
