import React, { FC } from 'react'

import { RedemptionCenterCondition } from '../../../offer/redemptionCenterCondition'
import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'
import WaitingMsg from './WaitingMsg/WaitingMsg'
import SuccessfulMsg from './SuccessfulMsg/SuccessfulMsg'
import { ProviderStatusType } from '../../providerStatusType'
import Spinner from '../../../ui/animation/Spinner/Spinner'

import './ResultDialog.scss'

interface SuccessfulDialogProps {
  isOpen: boolean
  setIsOpen: (flag: boolean) => void
  isSpinner: boolean
  providerStatus: ProviderStatus | undefined
  label: string
}

const ResultDialog: FC<SuccessfulDialogProps> = ({
  isOpen,
  setIsOpen,
  isSpinner = true,
  providerStatus,
  label = ''
}) => {
  const handleClose = (): void => {
    setIsOpen(false)
  }

  return (
    <BaseDrawer
      isOpen={isOpen}
      isCentered
      centerCondition={RedemptionCenterCondition.both}
      onClose={!isSpinner ? handleClose : () => {}}
    >
      <div className={`provider-result-dialog ${isSpinner ? 'provider-result-dialog__spinner' : ''}`}>
        {isSpinner && <Spinner />}
        {!isSpinner && providerStatus !== undefined &&
          <>
            {providerStatus.status === ProviderStatusType.Connected &&
              <SuccessfulMsg handleOkBtn={handleClose} label={label} />}
            {providerStatus.status === ProviderStatusType.Validating &&
              <WaitingMsg handleOkBtn={handleClose} />}
          </>}
      </div>
    </BaseDrawer>
  )
}

export default ResultDialog
