export const regexEmail =
  /^([a-zA-Z0-9]{1})([a-zA-Z0-9.]{1,})([a-zA-Z0-9_\-.#!$%&+\-/=_*^{}~]{0,})@([a-zA-Z0-9])([a-zA-Z0-9-]{0,})\.([a-zA-Z-.]{1,})([a-zA-Z])$/
export const regexDotBeforeAlpha = /(?=\.@)/
export const regexConsecutiveDot = /(?=\.\.)/
export const regexName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/
export const regexInvalidSpecialChars = /[^#!$%&+-/=_*^{}~a-zA-Z0-9]+/
export const regexPasswordComposition =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[#!$%&+-/=_*^{}~]).*$/
export const emailMaxLength = 40
export const emailMinLength = 7
export const passwordMinLength = 8
export const passwordMaxLength = 64
export const nameMinLength = 3
export const nameMaxLength = 25

export const NO_ATTEMPT_LEFT = 'No attempt left'
export const ONE_ATTEMPT_LEFT = 'One attempt left'
export const RESET_PASSWORD_FIELDS = {
  CURRENT_PASSWORD: 'currentPassword',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_NEW_PASSWORD: 'confirmNewPassword',
  PASSWORD: 'password'
}
export const EXISTING_EMAIL = 'Email already exists'
export const WRONG_PASSWORD = 'Wrong Password'
export const TEXT = 'text'
export const PASSWORD = 'password'
export const STATUS_OK = 200

export const INCORRECT_PASSWORD = 'Password is not correct.'
export const ACCOUNT_NOT_FOUND =
  'This e-mail or phone number is not associated with a Velocia account'
export const RES_TYPE_GET_USER_INFO = 'GET_USER_INFO'
export const INVITE_CODE_WARNING = 'Invite code is invalid but user is signed up.'
export const INVITE_CODE_ERROR = 'Promotion Code is invalid due to usage limit'
export const AUTH_INDICATOR_SIZE = 70
